// the widest letter in Inter font is the letter "W"
export const INTER_FONT_WIDEST_WIDTH_BY_SIZE = {
  8: 7.5937,
  9: 8.5468,
  10: 9.4921,
  11: 10.4375,
  12: 11.3906,
  13: 12.3359,
  14: 13.2891,
  15: 14.2344,
  16: 15.1875,
  17: 16.1328,
  18: 17.0859,
};
